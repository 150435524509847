import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { AccountService } from './_services/account.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isLoggedInStream: Observable<boolean>;

  constructor(private accountService: AccountService, private router: Router) {}

  ngOnInit(): void {
    this.isLoggedInStream = this.accountService.isLoggedInStream.asObservable();

    this.accountService.init().subscribe();
  }

  logout() {
    this.accountService
      .logout()
      .pipe(
        concatMap(() => {
          return from(this.goToLogin());
        }),
      )
      .subscribe();
  }

  async goToLogin() {
    return this.router.navigate(['/login']);
  }
}