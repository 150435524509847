import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AccountService } from '../_services/account.service';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private accountService: AccountService) {}

  private setAuthorizationHeader(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      headers: req.headers.set(
        'Authorization',
        `Bearer ${this.accountService.accessToken}`
      ),
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.accountService.accessToken) {
      req = this.setAuthorizationHeader(req);
    }

    return next.handle(req).pipe(
      switchMap((event) => {
        if (event instanceof HttpResponse) {
          if (!event.body.success && event.body.extras?.jwt) {
            return this.accountService
              .refreshTokens()
              .pipe(
                switchMap(() => next.handle(this.setAuthorizationHeader(req)))
              );
          }
        }

        return of(event);
      })
    );
  }
}
