import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginDto } from '../_interfaces/login.dto';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  accessToken: string = null;
  refreshToken: string = null;

  isLoggedInStream: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private httpClient: HttpClient) {}

  init(): Observable<any> {
    this.accessToken = window.localStorage.getItem('accessToken');
    this.refreshToken = window.localStorage.getItem('refreshToken');

    if (this.accessToken) {
      this.isLoggedInStream.next(true);
    }

    return of(null);
  }

  login(dto: LoginDto): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.baseApiUrl}/account/login`, {
        username: dto.username,
        password: dto.password,
        // TODO: For now like this, replace with real country or no need at all
        appCountryId: 117,
      })
      .pipe(
        tap((res) => {
          if (!res.success) {
            return;
          }

          this.accessToken = res.data.accessToken;
          this.refreshToken = res.data.refreshToken;

          if (!dto.shouldRemember) {
            return;
          }

          window.localStorage.setItem('accessToken', res.data.accessToken);
          window.localStorage.setItem('refreshToken', res.data.refreshToken);

          this.isLoggedInStream.next(true);
        })
      );
  }

  refreshTokens(): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.baseApiUrl}/account/refreshTokens`, {
        refreshToken: this.refreshToken,
      })
      .pipe(
        tap((res) => {
          if (!res.success) {
            return;
          }

          this.accessToken = res.data.accessToken;
          this.refreshToken = res.data.refreshToken;

          const accessToken = window.localStorage.getItem('accessToken');

          if (!accessToken) {
            return;
          }

          window.localStorage.setItem('accessToken', res.data.accessToken);
          window.localStorage.setItem('refreshToken', res.data.refreshToken);
        })
      );
  }

  logout(): Observable<any> {
    this.isLoggedInStream.next(false);

    window.localStorage.clear();

    return of(null);
  }
}
