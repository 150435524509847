import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ErrorDialog } from './error-dialog';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox'
import {MatGridListModule} from '@angular/material/grid-list';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {MatTableModule} from '@angular/material/table';
import {MatBadgeModule} from '@angular/material/badge';
import {MatChipsModule} from '@angular/material/chips';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    MatDialogModule,
    MatButtonModule,
    MatToolbarModule,
	MatInputModule,
	MatSelectModule,
	MatCheckboxModule,
	MatGridListModule,
	MatFormFieldModule,
	MatIconModule,
	MatCardModule,
	MatButtonToggleModule,
	MatDividerModule,
	MatListModule,
	MatTableModule,
	MatBadgeModule,
	MatChipsModule,
	MatTabsModule,
	MatDatepickerModule,
	MatPaginatorModule,
  ],
  declarations: [ErrorDialog],
})
export class ErrorDialogModule {}
